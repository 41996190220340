const React = require('react');
const PropTypes = require('prop-types');
const isEmpty = require('lodash/isEmpty'); // eslint-disable-line import/no-extraneous-dependencies
const displayName = require('../display-name');
const componentCtrService = require('../../../services/component-ctr');

function withTrackerOnPrint(WrappedComponent) {
  class WithTrackerOnPrint extends React.Component {
    componentDidMount() {
      const { extra_track: extraTrack } = this.props;

      if (!isEmpty(extraTrack)) {
        componentCtrService.trackRecommendationsExtraTrack(extraTrack);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithTrackerOnPrint.displayName = displayName(WithTrackerOnPrint, WrappedComponent);
  WithTrackerOnPrint.defaultProps = {
    extra_track: null,
  };

  WithTrackerOnPrint.propTypes = {
    extra_track: PropTypes.object,
  };

  return WithTrackerOnPrint;
}

module.exports = withTrackerOnPrint;
