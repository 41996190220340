const PropTypes = require('prop-types');
const React = require('react');
const CarouselSnapped = require('@andes/carousel-snapped');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');
const OfficialStores = require('./official-stores');
const SlideDesktop = require('./slide-desktop');

const OfficialStoresDesktop = ({ lazyLoad, lowEnd, items, ...props }) => {
  const slides = items.map(element => ({
    ...element,
    id: element.permalink,
    lazyLoad,
    lowEnd,
  }));
  if(slides.length < 1 ) return null;
  return (
    <OfficialStores {...props}>
      <CarouselSnapped
        srLabel="carousel"
        arrows={{
          size: "large",
          visibility: 'hover',
        }}
        pagination={false}
        strictBoundaries={false}
        spacing={16}
        slidesPerView={4}
      >
        {slides.map(item => (
          <CarouselSnappedSlide key={item.id}>
            <SlideDesktop {...item} />
          </CarouselSnappedSlide>
      ))}
      </CarouselSnapped>
    </OfficialStores>
  );
};

OfficialStoresDesktop.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      permalink: PropTypes.string.isRequired,
    })
  ).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  lowEnd: PropTypes.bool.isRequired,
};

module.exports = OfficialStoresDesktop;
