const React = require('react');
const Image = require('nordic/image');
const PropTypes = require('prop-types');
const withTracker = require('../../../commons/with-tracker');

const CityCard = ({ picture, title, hideImage, target_url: targetUrl }) => (
  <a className="city" href={targetUrl}>
    {!hideImage && (
      <div className="city__shadow">
        <Image className="city__image" alt={title} src={picture.src} />
      </div>
    )}
    <p className="city__title">{title}</p>
  </a>
);

CityCard.propTypes = {
  // eslint-disable-next-line react/require-default-props
  picture: PropTypes.shape({
    src: PropTypes.string,
  }),
  target_url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hideImage: PropTypes.bool,
};

CityCard.defaultProps = {
  hideImage: false,
};

module.exports = withTracker(CityCard);
