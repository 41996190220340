/* eslint-disable react/no-array-index-key */
const React = require('react');
const PropTypes = require('prop-types');
const key = require('../../../../../lib/weak-key');
const Section = require('../../../commons/section');

const MostSearched = ({ title, type, categories }) => {
  const elements = categories.map((category) => {
    const locations = category.locations.map(
      (location) => <li key={key(location)}><a href={location.url}>{location.name}</a></li>);
    return (
      <ul key={key(category)}>
        <li className="most-searched__category-title">{category.title}</li>
        {locations}
      </ul>
    );
  });

  return (
    <Section type={type}>
      <div className="row container">
        <div className="section-header">
          <h2>{title}</h2>
        </div>
        <div className="most-searched_container">
          {elements}
        </div>
      </div>
    </Section>
  );
};

MostSearched.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = MostSearched;
