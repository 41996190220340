const React = require('react');
const PropTypes = require('prop-types');
const Advertisement = require('../../../commons/advertisement');
const Section = require('../../../commons/section');

function AdvertisingBillboard(props) {
  const { type, lowEnd, currentUser, ...iframeAdProps } = props;

  if (lowEnd) {
    return null;
  }

  return (
    <Section type={type} className="AD_area">
      <Advertisement className="container billboard" currentUser={currentUser} {...iframeAdProps} />
    </Section>
  );
}

AdvertisingBillboard.propTypes = {
  type: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nickname: PropTypes.string.isRequired,
  }),
};

AdvertisingBillboard.defaultProps = {
  currentUser: null,
};

module.exports = AdvertisingBillboard;
