const React = require('react');

const Cross = () => (
  <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Historial" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-4" fill="#FFFFFF">
        <rect id="Rectangle" transform="translate(4.000000, 4.000000) rotate(-45.000000) translate(-4.000000, -4.000000) " x="0" y="3" width="8" height="2" />
        <rect id="Rectangle-Copy" transform="translate(4.000000, 4.000000) rotate(45.000000) translate(-4.000000, -4.000000) " x="0" y="3" width="8" height="2" />
      </g>
    </g>
  </svg>
);

module.exports = Cross;