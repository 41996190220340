const PropTypes = require('prop-types');
const React = require('react');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies
const withTracker = require('../../../commons/with-tracker');
const Image = require('../../../commons/image');
const { trackEvent } = require('../../../../../client/services/tracks');

const onCardClick = (target, tracks) => (e) => {

  e.stopPropagation();
  const parent = e.currentTarget;

  // honor links over target
  if (!parent.href) {
    e.preventDefault();

    // allow users to open a new tab or window
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.which === 2) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(target);
    } else {
      window.location = target;
    }
  }

  trackEvent(tracks, {}, true);
};

const OfficialStoresSlide = ({
  lazyLoad,
  lowEnd,
  background_logo,
  logo,
  name,
  permalink,
  logoSize,
  tracks,
  view_more_action: { target, label },
}) => (
    <div
      className={classNames('ui-card', 'official-stores-slide', { 'is-link': !lowEnd })}
      role="link"
      onClickCapture={onCardClick(permalink, tracks)}
    >
      <div className="official-stores-slide-background">
        <Image {...background_logo} lazyLoad={lazyLoad} role='presentation' />
      </div>
      <div className="official-stores-slide-logo">
        <Image {...logo} size={logoSize} lazyLoad={lazyLoad} alt={name} />
      </div>
      <div className="title-wrapper">
        <div className="official-stores-slide-title">{name}</div>
      </div>
      <div className="official-stores-slide-action">
        <a href={target}>
          {label}
        </a>
      </div>
    </div>
  );

OfficialStoresSlide.propTypes = {
  background_logo: PropTypes.shape(Image.propTypes),
  lazyLoad: PropTypes.bool.isRequired,
  logo: PropTypes.shape(Image.propTypes).isRequired,
  logoSize: PropTypes.string,
  lowEnd: PropTypes.bool.isRequired,
  permalink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

OfficialStoresSlide.defaultProps = {
  background_logo: {
    src: '',
    src2x: '',
  },
  logoSize: '80',
};

module.exports = withTracker(OfficialStoresSlide);
