const { trackEvent } = require('../../../../../../client/services/tracks');

const MD_EVENT = {
  provider: 'MELIDATA',
  type: 'event',
};

const MELIDATA = {
  SEARCH: {
    ...MD_EVENT,
    path: '/home/category/real_estate/faceted_search',
  },
  MAP: {
    ...MD_EVENT,
    path: '/home/category/real_estate/faceted_search_map',
  },
  CODE: {
    ...MD_EVENT,
    path: '/home/category/real_estate/findby_code',
  },
};

const getEventDataFromState = (facetedState = {}) => ({
  operation_id: facetedState.OPERATION,
  category_id: facetedState.category?.split('_')[0],
  property_type_id: facetedState.category?.split('_')[1],
  location_city: facetedState.city,
  is_development: facetedState.OPERATION_SUBTYPE,
  location_state: facetedState.state,
  location_neighborhood: facetedState.neighborhood,
});

const trackSearchEvent = (facetedState) => {
  const melidata = { ...MELIDATA.SEARCH, event_data: getEventDataFromState(facetedState) };
  trackEvent([melidata]);
};

const trackSearchMapEvent = (facetedState) => {
  const melidata = { ...MELIDATA.MAP, event_data: getEventDataFromState(facetedState) };
  trackEvent([melidata]);
};

const trackFindByCodeEvent = () => {
  const melidata = MELIDATA.CODE;
  trackEvent([melidata]);
};

module.exports = { trackSearchEvent, trackSearchMapEvent, trackFindByCodeEvent };
