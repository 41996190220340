const React = require('react');
const Home = require('./home');
const AdvertisingBillboard = require('./advertising-billboard');
const Cities = require('./cities/desktop');
const Advice = require('./advice/desktop');
const Discovery = require('./discovery');
const Exhibitor = require('./exhibitor');
const Navigation = require('./navigation/desktop');
const Recommendations = require('../../commons/recommendations/desktop');
const SearchBar = require('./search-bar/desktop');
const PublishWithUs = require('./publish-with-us');
const MostSearched = require('./most-searched/desktop');
const OfficialStoresDesktop = require('./official-stores/desktop');


const components = {
  'advertising-billboard': AdvertisingBillboard,
  cities: Cities,
  advice: Advice,
  'official-stores': OfficialStoresDesktop,
  discovery: Discovery,
  exhibitor: Exhibitor,
  navigation: Navigation,
  recommendations: Recommendations,
  'search-bar': SearchBar,
  'publish-with-us': PublishWithUs,
  'most-searched': MostSearched,
};

function HomeDesktop(props) {
  return <Home {...props} availableComponents={components} />;
}

module.exports = HomeDesktop;
