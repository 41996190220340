/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const { VisuallyHidden } = require('@andes/common');
const { useI18n } = require('nordic/i18n');
const heartFullIcon = require('./glyphs/heart-full');
const heartEmptyIcon = require('./glyphs/heart-empty');

/**
 * Shipping Component
 */
const BookmarkButton = ({ bookmarked, onClick }) => {
  const Icon = !bookmarked ? heartEmptyIcon : heartFullIcon;
  const classNames = 'ui-item__bookmark';
  const { i18n } = useI18n();
  return (
    <button
      className={bookmarked ? `${classNames} ui-item__bookmark--added` : classNames}
      onClick={onClick}
    >
      <VisuallyHidden>Favorito</VisuallyHidden>
      <Icon className="ui-item__bookmark-icon" />
    </button>
  );
};

/**
 * PropTypes
 */
BookmarkButton.propTypes = {
  bookmarked: PropTypes.bool,
  onClick: PropTypes.func,
};

/**
 * DefaultProps
 */
BookmarkButton.defaultProps = {
  bookmarked: false,
  onClick: null,
};

/**
 * Expose BookmarkButton
 */
module.exports = BookmarkButton;
