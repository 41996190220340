const React = require('react');
const PropTypes = require('prop-types');
const Image = require('../../image');

const LastBuySeed = ({ target_url: targetUrl, items, title, sub_title: subTitle }) => (
  <div className="highlight-seed ui-item__wrapper trigger-item__wrapper">
    <a href={targetUrl} className="ui-item seed">
      <div className="ui-item__image-container">
        <div className="quantity-1">
          <div className="col-image">
            <Image {...items[0].picture} />
          </div>
        </div>
      </div>

      <div className="ui-item__content">
        <h2>{title}</h2>
        <p className="ui-item__title">{subTitle}</p>
      </div>
    </a>
  </div>
);

LastBuySeed.propTypes = {
  target_url: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.shape(Image.propTypes),
    })
  ).isRequired,
  sub_title: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = LastBuySeed;
