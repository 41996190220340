const React = require('react');
const PropTypes = require('prop-types');
const Action = require('../../../commons/action');
const Section = require('../../../commons/section');

function Navigation({ type, title, view_more_action: viewMoreAction, children }) {
  return (
    <Section type={type}>
      <div className="row container">
        <div className="section-header">
          <h2>{title}</h2>
          <Action className="view-history" {...viewMoreAction} />
        </div>
        {children}
      </div>
    </Section>
  );
}

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes),
};

Navigation.defaultProps = {
  view_more_action: null,
};

module.exports = Navigation;
