const itemHelper = {
  discountSuffix: ' OFF',

  getDiscount(item, decimalPlaces, thousandSeparator) {
    if (!item.discount_label || !item.price.original_price) {
      return null;
    }
    const { currency_id, currency_symbol, original_price } = item.price;
    return {
      text: item.discount_label + itemHelper.discountSuffix,
      original_price: {
        currency_id,
        symbol: currency_symbol,
        fraction: itemHelper.getFormattedFraction(original_price, thousandSeparator),
        cents: itemHelper.getDecimalPart(original_price, decimalPlaces),
      },
    };
  },

  getDecimalPart(price, decimalPlaces) {
    const decimalPart = Math.floor((price - Math.floor(price)) * (10 ** decimalPlaces));
    let decimalString = '00';
    if (decimalPart !== 0) {
      decimalString = decimalPart.toString();
    }
    return decimalString;
  },

  getFormattedFraction(price, separator) {
    const rawFraction = Math.floor(price).toString();
    return rawFraction.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, n => `${n}${separator}`);
  },

  getShipping({ free_shipping: freeShipping, label: text }) {
    if (!freeShipping || !text) {
      return null;
    }

    return { freeShipping, text };
  },
};

module.exports = itemHelper;
