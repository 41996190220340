const PropTypes = require('prop-types');
const React = require('react');
const Section = require('../../../commons/section');
const Action = require('../../../commons/action');
const withTracker = require('../../../commons/with-tracker');
const { trackEvent } = require('../../../../../client/services/tracks');


const onTitleClick = (tracks) => () => {
  trackEvent(tracks, {}, true);
};

const OfficialStores = ({ type, title, view_more_action, tracks, children }) => (
  <Section type={type}>
    <div className="row container">
      <div className="section-header">
        <h2>{title} <Action {...view_more_action} onClick={onTitleClick(tracks)} /></h2>
      </div>
      {children}
    </div>
  </Section>
);

OfficialStores.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes).isRequired,
};

module.exports = withTracker(OfficialStores);
