const React = require('react');
const Recommendations = require('./recommendations');

function RecommendationsDesktop(props) {
  return (
    <Recommendations {...props} />
  );
}

module.exports = RecommendationsDesktop;
