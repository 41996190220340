const React = require('react');
const PropTypes = require('prop-types');
const CarouselSnapped = require('@andes/carousel-snapped');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');
const CityCard = require('./city-card');
const Section = require('../../../commons/section');
const withTracker = require('../../../commons/with-tracker');

function divideByGroupsOf(arr, groupsOf) {
  const groups = [];

  for (let i = 0, n = arr.length / groupsOf; i < n; i += 1) {
    const slice = arr.slice(groupsOf * i, groupsOf * (i + 1));
    groups.push({
      items: slice,
      id: slice.map(c => c.id).join('-'),
    });
  }

  return groups;
}

// Assigning these propTypes to a variable let us add an `isRequired` on each use so eslint understands what we mean.
const itemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    target_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    picture: PropTypes.shape({
      src: PropTypes.string,
    }).isRequired,
  })
);

function CitiesColumn({ items }) {
  const missingBlocks = 2 - (items.length % 2);
  return (
    <div className="city-column">
      {items.map(item => <CityCard key={item.id} {...item} />)}
      {missingBlocks < 2 && <div className="filler" />}
    </div>
  );
}

CitiesColumn.propTypes = {
  items: itemsPropType.isRequired,
};

class CitiesDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      citiesByColumn: 2,
    };
  }

  render() {
    const { items: propItems, type, title, deviceType } = this.props;
    const { citiesByColumn } = this.state;
    const items = divideByGroupsOf(propItems, citiesByColumn);
    if(items.length < 1 ) return null;
    return (
      <Section type={type}>
        <div className="row container">
          <div className="section-header">
            <h2>{title}</h2>
          </div>
          <div className="wrapper">
            <CarouselSnapped
              srLabel="carousel"
              pagination={false}
              spacing={0}
              slidesPerView={deviceType === 'desktop' ? 7 : 4}
              strictBoundaries={false}
            >
              {items.map(item => (
                <CarouselSnappedSlide key={item.id}>
                  <CitiesColumn {...item} />
                </CarouselSnappedSlide>
              ))}
            </CarouselSnapped>
          </div>
        </div>
      </Section>
    );
  }
}

CitiesDesktop.propTypes = {
  items: itemsPropType.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
};

module.exports = withTracker(CitiesDesktop);
