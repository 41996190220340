const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies

/* @TODO check if we can replace with nordic Image component */
/* eslint-disable nordic/no-img-tags */
function Image({ className, mounted, lazyLoad, src, src2x, alt, size, originalSrc, role }) {
  if (!mounted && lazyLoad) {
    return (
      <img
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        data-src={src}
        className={classNames(className, 'lazy-loadable')}
        alt={alt}
        data-srcset={src2x ? `${src} 1x, ${src2x} 2x` : null}
        width={size}
        height={size}
        role={role}
      />
    );
  }
  return (
    <img
      src={src}
      data-original-src={originalSrc}
      className={className}
      alt={alt}
      srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
      width={size}
      height={size}
      role={role}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
  mounted: PropTypes.bool,
  src: PropTypes.string.isRequired,
  src2x: PropTypes.string,
  size: PropTypes.string,
  originalSrc: PropTypes.string,
};

Image.defaultProps = {
  alt: null,
  className: null,
  lazyLoad: false,
  mounted: true,
  src2x: null,
  size: null,
  originalSrc: null,
};

module.exports = Image;
