const React = require('react');
const CarouselSnapped = require('@andes/carousel-snapped');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');
const PropTypes = require('prop-types');
const Advice = require('./index');
const AdviceItem = require('../../../commons/advice-item/index');
const Utils = require('../../../../utils');

const AdviceDesktopContainer = ({ items, title }) => {
  const maxTitleLenght = 54;
  const formattedElements = Utils.ellipsisTrim(items, 'title', maxTitleLenght);
  if(formattedElements.length < 0) return null;
  return (
    <Advice className="advice-desktop" title={title}>
      <CarouselSnapped
        srLabel="carousel"
        pagination={false}
        strictBoundaries={false}
        spacing={0}
        slidesPerView={4}
      >
        {formattedElements.map(item => (
          <CarouselSnappedSlide key={item.id}>
            <AdviceItem {...item} />
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
    </Advice>
  );
};

AdviceDesktopContainer.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = AdviceDesktopContainer;
