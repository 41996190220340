const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies

const Section = ({ type, className, style, children }) => (
  <section data-testid="section-component" className={classNames(type, className)} style={style} type={type}>
    {children}
  </section>
);

Section.defaultProps = {
  children: null,
  className: null,
  style: null,
  type: null,
};

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
};

module.exports = Section;
