const logger = require('nordic/logger');

const trackMelidata = (track, experiments, clean) => {
  const actionType = clean ? 'cleanAndSend' : 'send';
  if (window && window.melidata !== undefined) {
    window.melidata(actionType, 'event', {
      path: track.path,
      data: track.event_data,
      experiments,
    });
  }
};

exports.trackEvent = function trackEvent(tracks, experiments = {}, clean = false) {
  tracks.forEach((track) => {
    switch (track.provider) {
      case 'MELIDATA':
        trackMelidata(track, experiments, clean);
        break;
      default:
        logger.info(`${track.provider} track is not supported`);
    }
  });
};
