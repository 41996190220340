const React = require('react');
const PropTypes = require('prop-types');
const Button = require('@andes/button');
const Action = require('../../../commons/action');
const Image = require('../../../commons/image');
const withTracker = require('../../../commons/with-tracker');

const Advertising = ({
  picture,
  title,
  lazyLoad,
  view_more_action: viewMoreAction,
  text1,
  text2,
  deviceType,
}) => (
  <a className="advertising" href={viewMoreAction.target}>
    <div className="advertising__image">
      <Image {...picture} alt={title} lazyLoad={lazyLoad} />
    </div>
    <div className="advertising__info">
      <h3 className="advertising__info__title">{title}</h3>
      <h3 className="advertising__info__text advertising__info__text--bold">{text1}</h3>
      <h3 className="advertising__info__text advertising__info__text--bold">{text2}</h3>
      {deviceType === 'desktop' ? (
        <Button className="advertising__info__button">
          {viewMoreAction.label}
        </Button>
      )
        : (
          <p className="advertising__info__link">
            {viewMoreAction.label}
          </p>
      )}
    </div>
  </a>
);

Advertising.propTypes = {
  title: PropTypes.string.isRequired,
  picture: PropTypes.shape(Image.propTypes).isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
};

module.exports = withTracker(Advertising);
