const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies
const gd = require('../../../global');

class Advertisement extends React.Component {
  constructor(props) {
    super(props);
    this.slotId = `slot_${props.google_ad.position}`;
    this.state = {
      loaded: false,
      show: props.startExpanded,
    };
  }

  componentDidMount() {
    if (!gd.gptadslots) {
      gd.gptadslots = [];
    }
    if (!gd.googletag) {
      gd.googletag = { cmd: [] };
    }
    if (!gd.gptScript) {
      const { document } = gd;
      const script = document.createElement('script');
      gd.gptScript = script;

      script.type = 'text/javascript';
      script.src = '//www.googletagservices.com/tag/js/gpt.js';
      script.async = true;

      document.querySelector('script')?.parentNode.appendChild(script);
    }

    const { googletag, gptadslots } = gd;
    const { google_ad: googleAd, deviceType } = this.props;
    const isMobile = deviceType === 'mobile';
    const { url, currentUser } = this.props;

    googletag.cmd.push(
      () => {
        googletag.pubads().set('page_url', url);
        gptadslots.push(
          googletag
            .defineSlot(googleAd.unit, isMobile ? 'fluid' : googleAd.size, this.slotId)
            .setTargeting('platform', [isMobile ? 'webmobile' : 'desktop'])
            .setTargeting('Posiciones', [googleAd.position])
            .addService(googletag.pubads())
        );
        googletag.pubads().enableSingleRequest();
        if (currentUser) {
          googletag.pubads().setTargeting('custID', [currentUser.id]);
        }
        googletag.pubads().enableAsyncRendering();
        googletag.enableServices();
      },
      () => {
        this.slot = googletag.display(this.slotId);
        googletag.pubads().addEventListener('slotRenderEnded', (e) => {
          this.setState({
            loaded: true,
            show: !e.isEmpty,
          });
        });
      }
    );
  }

  componentWillUnmount() {
    const { googletag } = gd;
    googletag.cmd.push(
      () => googletag.destroySlots([this.slot])
    );
  }

  render() {
    const { className } = this.props;
    const { show, loaded } = this.state;
    const classes = classNames('advertisement', className, {
      hidden: !show,
      loaded,
    });
    const frameClassName = classNames('frame-container', classes);
    return (
      <div data-testid="advertisement-component" className={classes}>
        <div data-testid="ad-slot" className={frameClassName} id={this.slotId} />
      </div>
    );
  }
}

Advertisement.defaultProps = {
  caption: null,
  currentUser: null,
  startExpanded: true,
};

Advertisement.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  google_ad: PropTypes.shape({
    size: PropTypes.arrayOf(PropTypes.number).isRequired,
    unit: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
  }).isRequired,
  startExpanded: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

module.exports = Advertisement;
