/* global melidata:false */

/**
* Service interface
*/
class ComponentCtrService {
  static notifyPrint(track) {
    this.notify(track, 'print');
  }

  static notify(track, eventName) {
    const meliTrack = {};
    melidata('createEventTrack', meliTrack);
    melidata('withPath', '/component', meliTrack);
    melidata('withData', { c_event: eventName, ...track }, meliTrack);
    melidata('sendTrack', meliTrack, 'component_prints');
  }

  static trackRecommendationsExtraTrack(extraTrack) {
    /*
     * @TODO tobe removed once Recommendations BE verifies custom stream it's ok
     */
    const meliTrack = {};
    melidata('createEventTrack', meliTrack);
    melidata('withPath', '/recommendations', meliTrack);
    melidata('withData', extraTrack, meliTrack);
    melidata('sendTrack', meliTrack);

    const meliTrackDedicatedStream = {};
    melidata('createEventTrack', meliTrackDedicatedStream);
    melidata('withPath', '/recommendations/print', meliTrackDedicatedStream);
    melidata('withData', extraTrack, meliTrackDedicatedStream);
    melidata('withStream', 'recommendations', meliTrackDedicatedStream);
    melidata('sendTrack', meliTrackDedicatedStream);
  }
}

/**
* Expose Service
*/
module.exports = ComponentCtrService;
