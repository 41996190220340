const React = require('react');
const classnames = require('classnames');
const Action = require('./action');
const Image = require('./image-desktop');
const PriceBlock = require('./price-block');
const BookmarkButton = require('./bookmark-button');
const Props = require('./props');

const Item = ({
  id,
  className,
  url,
  location,
  showTitle,
  title,
  image,
  imgSize,
  lazyLoad,
  isFirstPage,
  lowEnd,
  price,
  price_context_message,
  operation_estate_label,
  history,
  attributes,
  actions,
  condition,
  bookmarked,
  onFavClick,
}) => {
  const Element = url ? 'a' : 'div';
  const neighborhood = location && location.neighborhood && location.neighborhood.name ?
    location.neighborhood.name : null;
  const city = location && location.city && location.city.name ?
    location.city.name : null;
  const locationText = neighborhood || city;

  return (
    <Element
      href={url}
      className={classnames('ui-item', className)}
      itemScope
      itemType="http://schema.org/Product"
      title={showTitle ? title : null}
    >
      <Image
        {...image}
        size={imgSize}
        title={title}
        lazyLoad={lazyLoad}
        isFirstPage={isFirstPage}
        isLowEnd={lowEnd}
      />
      <div className="ui-item__content">
        {price && <PriceBlock price={price} price_context_message={price_context_message} />}
        {operation_estate_label && <span className="ui-item__top-title">{operation_estate_label}</span>}
        {title && <span className="ui-item__bottom-title">{title}</span>}
        {location && location.address_line && (
          <span className="ui-item__middle-title">{location.address_line}</span>
        )}
        {locationText && <span className="ui-item__bottom-title">{locationText}</span>}
        {!history && attributes && attributes.map(element => (
          <p className="ui-item__title" itemProp="name" key={element}>{element}</p>)
        )}
        {actions.map(action => <Action key={action.id} {...action} />)}
      </div>
      <BookmarkButton id={id} bookmarked={bookmarked} onClick={onFavClick} />
      {url && <meta itemProp="url" content={url} />}
      {condition && <meta itemProp="itemCondition" content={condition} />}
      {id && <meta itemProp="productID" content={id} />}
    </Element>
  );
};

Item.propTypes = Props.propTypes;
Item.defaultProps = Props.defaultProps;

module.exports = Item;
