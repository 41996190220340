const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies
const injectI18n = require('nordic/i18n/injectI18n');
const Action = require('../action');
const Section = require('../section');

const RecommendationsContainer = ({
  type,
  highlight_seeds: highlightSeeds,
  title,
  view_more_action: viewMoreAction,
  deviceType,
  id,
  children,
}) => (
  <Section type={type}>
    <div className={classNames('row container', { 'highlight-seeds': highlightSeeds })}>
      <div className="section-header">
        <h2>{title}</h2>
        {viewMoreAction && (
          <Action
            label={deviceType === 'mobile' ? 'Ver más' : viewMoreAction.label}
            target={`/pi/home/lists/${id}`}
          />
        )}
      </div>
      {children}
    </div>
  </Section>
);

RecommendationsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  highlight_seeds: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes),
};

RecommendationsContainer.defaultProps = {
  highlight_seeds: false,
  view_more_action: null,
};

module.exports = injectI18n(RecommendationsContainer);
