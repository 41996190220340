const DEFAULT_URL = '/venta/departamento';
const MAP_URL_SUFFIX = '_DisplayType_M';
const DEFAULT_MAP_URL = `${DEFAULT_URL}/${MAP_URL_SUFFIX}`;

const FILTER_IDS = {
  OPERATION: 'OPERATION',
  PROPERTY_TYPE: 'PROPERTY_TYPE',
  OPERATION_SUBTYPE: 'OPERATION_SUBTYPE',
  CATEGORY: 'category',
  STATE: 'state',
  CITY: 'city',
  NEIGHBORHOOD: 'neighborhood',
  VISUAL_ID: 'visual_id',
};

const DEFAULT_FILTER_VALUES = {
  [FILTER_IDS.CATEGORY]: 'MLC1459',
  [FILTER_IDS.VISUAL_ID]: 'PIN',
};

const COMPONENT_IDS = {
  FACETED_SEARCH: 'faceted-search',
  SEARCH_SUBMIT: 'search-submit',
  MAP_SEARCH: 'map-search',
  SEARCH_BY_CODE: 'search-by-code',
  SEARCH_MENU: 'search-menu',
  BACK: 'back',
};

module.exports = {
  DEFAULT_URL,
  MAP_URL_SUFFIX,
  DEFAULT_MAP_URL,
  FILTER_IDS,
  DEFAULT_FILTER_VALUES,
  COMPONENT_IDS,
};
