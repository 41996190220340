const { findById } = require('./find-by-attribute');
const {
  FILTER_IDS: { OPERATION_SUBTYPE, CATEGORY, VISUAL_ID },
  DEFAULT_FILTER_VALUES,
} = require('./constants');

const pickTruthy = (params) => {
  const cleanParams = {};

  Object.keys(params).forEach((key) => {
    if (params[key] != null) {
      cleanParams[key] = params[key];
    }
  });

  return cleanParams;
};

const getSearchUrlParams = (state, renderConfig) => {
  const { values } = findById(renderConfig, OPERATION_SUBTYPE);
  const operationSubType = values[state[OPERATION_SUBTYPE]] || null;
  const [, PROPERTY_TYPE = null] = (state[CATEGORY] || '').split('_');

  return pickTruthy({
    ...state,
    category: DEFAULT_FILTER_VALUES[CATEGORY],
    PROPERTY_TYPE,
    OPERATION_SUBTYPE: operationSubType,
    visual_id: DEFAULT_FILTER_VALUES[VISUAL_ID],
  });
};

module.exports = { getSearchUrlParams };
