const React = require('react');
const PropTypes = require('prop-types');
const logger = require('nordic/logger');
const ErrorBoundary = require('../error-boundary');

const LAZY_LOAD_AFTER = 2;
const log = logger('app/components/component-list');

function ComponentList({ availableComponents, list, ...childrenProps }) {
  return (
    <div>
      {list.map((component, i) => {
        const ReactComponent = availableComponents[component.type];

        /* istanbul ignore if */
        if (!ReactComponent) {
          log.error(`Type '${component.type}' does not match any react component.`);
          return null;
        }

        return (
          <ErrorBoundary
            key={component.id || component.type}
            componentData={component}
            logger={logger('test')}
            SSR={childrenProps.isServerSide}
          >
            <ReactComponent lazyLoad={i > LAZY_LOAD_AFTER && !childrenProps.lowEnd} {...component} {...childrenProps} />
          </ErrorBoundary>
        );
      })}
    </div>
  );
}

ComponentList.propTypes = {
  availableComponents: PropTypes.object.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

module.exports = ComponentList;
