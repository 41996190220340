const React = require('react');

const PropTypes = require('prop-types');
const Section = require('../../../commons/section');

const Advice = (props) => {
  const { className, children, title } = props;
  return (
    <Section className={`advice ${className}`}>
      <div className="row container advice__row-container">
        <div className="section-header advice__header">
          <h2>{title}</h2>
        </div>

        {children}
      </div>
    </Section>
  );
};

Advice.defaultProps = {
  className: '',
};

Advice.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.object.isRequired,
};

module.exports = Advice;
