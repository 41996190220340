const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies
const SvgCross = require('./cross');
const Item = require('../../../commons/item');
const withTracker = require('../../../commons/with-tracker');

const FADEOUT_INTERVAL = 300;

class NavigationSlide extends React.Component {
  constructor(props) {
    super(props);
    this.startDeleteAnimation = this.startDeleteAnimation.bind(this);
    this.delete = this.delete.bind(this);
    this.state = { deleting: false };
  }

  startDeleteAnimation() {
    this.setState({ deleting: true }, () => setTimeout(this.delete, FADEOUT_INTERVAL));
  }

  delete() {
    const { restClient, delete: doDelete, id, showError } = this.props;
    restClient.delete(`/navigation/${id}`)
      .then(() => doDelete(id))
      .catch(() => {
        this.setState({ deleting: false }, showError());
      });
  }

  render() {
    const { deleting } = this.state;
    const { lowEnd } = this.props;

    return (
      <div className={classNames('navigation-slide', deleting && 'hide')}>
        {!lowEnd && (
          <div className="cross-container" disabled={deleting}>
            <div
              className="cross-circle"
              role="presentation"
              onClick={this.startDeleteAnimation}
              onKeyPress={this.startDeleteAnimation}
            >
              <div className="cross">
                <SvgCross />
              </div>
            </div>
          </div>
        )}
        <Item {...this.props} />
      </div>
    );
  }
}

NavigationSlide.propTypes = {
  delete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  restClient: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired,
  price: PropTypes.object.isRequired,
};

module.exports = withTracker(NavigationSlide);
