const React = require('react');
const PropTypes = require('prop-types');
const Image = require('nordic/image');
const Card = require('@andes/card');

const AdviceItem = ({ url, picture, title, description }) => (
  <a className="advice-item" href={url} target="_blank" rel="noopener noreferrer">
    <Image src={picture.src} alt={title} className="ui-item__image advice-item__image" />

    <div className="advice-item__content">
      {title ? <h3 className="advice-item__title">{title}</h3> : null}
      {description ? <p className="ui-item__description advice-item__description">{description}</p> : null}
    </div>
  </a>
);

AdviceItem.defaultProps = {
  url: '#',
};

AdviceItem.propTypes = {
  url: PropTypes.string,
  picture: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  description: PropTypes.string,
};

module.exports = props => (
  <Card className="advice-item__wrapper" paddingSize={0} data-testid="advice-item-id">
    <AdviceItem {...props} />
  </Card>
);
