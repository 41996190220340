const PropTypes = require('prop-types');

const priceShape = PropTypes.shape({
  currency_id: PropTypes.string,
  symbol: PropTypes.string,
  fraction: PropTypes.string.isRequired,
  decimal_separator: PropTypes.string,
  cents: PropTypes.string,
});

const propTypes = {
  /**
   * Required properties
   */
  id: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    src2x: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  /**
   * Optional properties
   */
  title: PropTypes.string,
  url: PropTypes.string,
  condition: PropTypes.string,
  className: PropTypes.string,
  tracking: PropTypes.string,
  bookmarked: PropTypes.bool,
  price_context_message: PropTypes.string,
  lazyLoad: PropTypes.bool,
  attributes: PropTypes.array,
  /**
   * Shaped props
   */
  price: priceShape,
  discount: PropTypes.shape({
    text: PropTypes.string.isRequired,
    original_price: priceShape.isRequired,
  }),
  review: PropTypes.shape({
    stars: PropTypes.number.isRequired,
    total: PropTypes.number,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  operation_estate_label: PropTypes.string,
  imgSize: PropTypes.string,
  isFirstPage: PropTypes.bool,
  lowEnd: PropTypes.bool,
  onFavClick: PropTypes.func,
};

const defaultProps = {
  actions: [],
  title: null,
  review: null,
  url: null,
  condition: null,
  tracking: null,
  id: null,
  attributes: [],
  price_context_message: null,
  discount: null,
  className: '',
  bookmarked: false,
  cuurency_id: null,
  symbol: null,
  isFirstPage: false,
  lowEnd: false,
  onFavClick: () => undefined,
};

module.exports = { propTypes, defaultProps };

