const React = require('react');
const PropTypes = require('prop-types');
const withLifeCycle = require('../with-life-cycle/desktop');

/* @TODO check if we can replace with nordic Image component */
/* eslint-disable nordic/no-img-tags */
const Image = ({ lazyLoad, mounted, src, size, src2x, title }) => (
  <div className="ui-item__image-container">
    <meta itemProp="image" content={src} />
    {lazyLoad && !mounted ? (
      <img
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        data-src={src}
        className="ui-item__image lazy-loadable"
        width={size}
        height={size}
        alt={title}
        data-srcset={src2x ? `${src} 1x, ${src2x} 2x` : null}
      />
    ) :
      (
        <img
          src={src}
          className="ui-item__image"
          width={size}
          height={size}
          alt={title}
          srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
        />
      )}
  </div>
);

Image.propTypes = {
  mounted: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  lazyLoad: PropTypes.bool,
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  src2x: PropTypes.string,
  title: PropTypes.string,
};

Image.defaultProps = {
  size: null,
  src2x: null,
  title: null,
};

module.exports = withLifeCycle(Image);
