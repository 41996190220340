const React = require('react');
const PropTypes = require('prop-types');
const key = require('../../../../../lib/weak-key');
const Section = require('../../../commons/section');
const Advertising = require('./advertising');
const withTracker = require('../../../commons/with-tracker');

const Discovery = ({ title, type, items, lazyLoad, deviceType, userAgent }) => {
  const lazyLoadValidator = !(/Edge/.test(userAgent));
  return (
    <Section type={type}>
      <div className="container row">
        {/* {deviceType === 'desktop' && */}
        <div className="section-header">
          <h2>{title}</h2>
        </div>
        {/* } */}
        <div className="discovery__body">
          {items.map((item, index) => {
            if (index < 2) {
              // eslint-disable-next-line react/no-array-index-key
              return (<Advertising key={key(item)} {...item} lazyLoad={lazyLoad && lazyLoadValidator} deviceType={deviceType} />);
            }
            return null;
          })}
        </div>
      </div>
    </Section>
  );
};
Discovery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  })).isRequired,
  lazyLoad: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  userAgent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

module.exports = withTracker(Discovery);
