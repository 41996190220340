const React = require('react');
const Script = require('nordic/script');

const ImageLoader = () => (
  <Script on="now">
    {`
      window.addEventListener('load', function() {
        var exhibitor = document.querySelector('.exhibitor-holder img');
        var originalSrc = exhibitor.getAttribute('data-original-src');
        if (exhibitor && originalSrc) {
          var img = new Image();
          img.onload = function() { exhibitor.src = img.src; };
          img.src = exhibitor.getAttribute('data-original-src');
          exhibitor.removeAttribute('data-original-src');
        }
      });
      var lazyImages = [].slice.call(document.querySelectorAll('img.lazy-loadable'));
      function loadImages(img) {
        if (img.hasAttribute('data-src')) {
            var datasrc = img.getAttribute('data-src');
            img.setAttribute('src', datasrc);
            img.removeAttribute('data-src');
          }
          if (img.hasAttribute('data-srcset')) {
            var datasrcSet = img.getAttribute('data-srcset');
            img.setAttribute('srcSet', datasrcSet);
            img.removeAttribute('data-srcset');
          }
          img.classList.remove('lazy-loadable');
          if (img.classList.length < 1) {
            img.removeAttribute('class');
          }
      }
      function loadAllImages() {
        lazyImages.forEach(function(lazyImage) {
          loadImages(lazyImage);
        });
      }
      if ('IntersectionObserver' in window) {
        var lazyImageObserver = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              var lazyImage = entry.target;
              loadImages(lazyImage);
              lazyImageObserver.unobserve(lazyImage);
            }
          });
        }, { rootMargin: '360px' });
        lazyImages.forEach(function(lazyImage) {
          lazyImageObserver.observe(lazyImage);
        });
      } else {
        if (document.readyState === 'complete') {
          loadAllImages();
        } else {
          window.addEventListener('load', function() {
            loadAllImages();
          });
        }
      }
    `}
  </Script>
);

module.exports = ImageLoader;
