// Webpack 5 (included with nordic 7) does not polyfill node.js modules such as
// Buffer by default. Instead of including a whole new library, we define the
// only encoding function we actually need.
// See reference: https://developer.mozilla.org/es/docs/Glossary/Base64
const escapeUnicodeChars = (_match, p1) => String.fromCharCode(`0x${p1}`);

const encodeToBase64 = (str) =>
  window ? window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, escapeUnicodeChars)) : '';

module.exports = encodeToBase64;
