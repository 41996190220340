const createFindByAttribute = (attribute) => (collection, attributeValue) => {
  for (let i = 0; i < collection.length; i += 1) {
    const element = collection[i];

    if (element[attribute] === attributeValue) {
      return element;
    }
  }

  return null;
};

const findById = createFindByAttribute('id');

module.exports = {
  createFindByAttribute,
  findById,
};
