/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const MoneyAmount = require('@andes/money-amount'); // eslint-disable-line import/no-extraneous-dependencies

/**
 * PriceBlock Component
 */
const PriceBlock = ({ price, price_context_message }) => (
  <div className="ui-item__price-block">
    {price.text ? <span className="ui-item__price">{price.text}</span> : null}
    {/**
     * price_context_message price: disabled if an original_price exists
     */}
    <span className="ui-item__price_context_message">{price_context_message}</span>
    {/**
     * The main price of the item
     */}
    {price.currency_id ? (
      <MoneyAmount
        className="ui-item__price"
        size={
          price.fraction?.length > 7 || price.value?.length > 7
          ? price.font_size_price : 24
        }
        currency_id={price.currency_id}
        symbol={price.symbol}
        value={{
          fraction: price.fraction || price.value,
          cents: parseInt(price.cents, 10) > 0 ? price.cents : '',
        }}
        decimal_separator={price.decimal_separator}
      />
    ) : null}
  </div>
);

PriceBlock.propTypes = {
  price: PropTypes.shape({
    value: PropTypes.number,
    currency_id: PropTypes.string,
    currency_symbol: PropTypes.string,
    symbol: PropTypes.string,
    fraction: PropTypes.string,
    cents: PropTypes.string,
    decimal_separator: PropTypes.string,
    text: PropTypes.string,
    font_size_price: PropTypes.number,
  }).isRequired,
  price_context_message: PropTypes.string,
};

PriceBlock.defaultProps = {
  price_context_message: null,
};

/**
 * Expose PriceBlock
 */
module.exports = PriceBlock;
