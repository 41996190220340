const React = require('react');
const PropTypes = require('prop-types');
const Image = require('nordic/image');
const Section = require('../../../commons/section');

const PublishWithUs = ({ main, message, action }) => (
    <Section className="publish-with-us" type="publish-with-us">
      <div className="row container publish-with-us__row-container">
        <div className="column publish-with-us__main">
          <Image alt={main.title} src={main.picture.src} />
          <h2>{main.title}</h2>
        </div>
        <div className="column publish-with-us__message">
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <div className="column publish-with-us__action">
          <a className="publish-with-us__action__btn" href={action.target}>{action.label}</a>
        </div>
      </div>
    </Section>
  );

PublishWithUs.propTypes = {
  main: PropTypes.shape({
    title: PropTypes.string,
    picture: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string,
    target: PropTypes.string,
  }).isRequired,
};

module.exports = PublishWithUs;
