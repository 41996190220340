const encodeToBase64 = require('../../../../../../lib/encode-to-base-64');
const { COOKIES } = require('../../../../../../lib/constants');

const saveCurrentSearch = (state = {}) => {
  if (typeof window !== "undefined" && document && document.cookie) {
    const currentSearch = JSON.stringify(state);
    const expiresDate = new Date();
    const encodeCurrentSearch = encodeToBase64(currentSearch);
    document.cookie = `${COOKIES.FACETED_SEARCH}=${encodeCurrentSearch};expires=${expiresDate};domain=.portalinmobilario.com;path=/`;
  }
};

const disableSavedSearchesTutorial = () => {
  if (typeof window !== 'undefined' && document && document.cookie) {
    document.cookie = `${COOKIES.SAVED_SEARCHES_TUTORIAL}=1`;
  }
};

module.exports = { saveCurrentSearch, disableSavedSearchesTutorial };
