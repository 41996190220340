const React = require('react');
const PropTypes = require('prop-types');
const Image = require('../../image');

const CartSeed = ({
  items,
  cart_item_count: cartItemCount,
  target_url: targetUrl,
  title,
  sub_title: subTitle,
}) => {
  const cartItemsLength = items.length;
  const imagesQuantity = cartItemCount > 4 ? 'more' : items.length;
  const moreItemsCount = cartItemCount - 3;
  const colsLength = Math.min(items.length, 2);
  return (
    <div className="highlight-seed ui-item__wrapper trigger-item__wrapper">
      <a href={targetUrl} className="ui-item seed">
        <div className="ui-item__image-container">
          <div className={`quantity-${imagesQuantity}`}>
            {items.slice(0, colsLength).map((seed, i) => (
              <div className="col-image" key={seed.id}>
                {items
                  .slice(
                    Math.floor(cartItemsLength / 2) * i,
                    Math.floor(cartItemsLength / 4) + 1 + (Math.ceil(cartItemsLength / 2) * i)
                  )
                  .map(item => (
                    <Image {...item.picture} key={item.id} />
                  ))}
                {i === 1 && imagesQuantity === 'more' &&
                  <div className="quantity">+ {moreItemsCount}</div>}
              </div>
            ))}
          </div>
        </div>

        <div className="ui-item__content">
          <h2>{title}</h2>
          <p className="ui-item__title">{subTitle}</p>
        </div>
      </a>
    </div>
  );
};

CartSeed.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  cart_item_count: PropTypes.number.isRequired,
  target_url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sub_title: PropTypes.string.isRequired,
};

module.exports = CartSeed;
